@import '../../../assets/variable.scss';

.Toastify__toast-container,
.Toastify__toast,
.Toastify__toast-body {
  padding: 0;
  box-shadow: none;
  width: 480px;
  cursor: initial;
  background: none;

  .alert {
    margin: 0;

    .btn-close:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
