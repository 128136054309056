@import '../../../assets/variable.scss';

.custom-stepper {
  width: 100%;
  display: flex;
  align-items: center;

  .stepper-button {
    text-align: center;
    width: 75px;
    height: 35px;
    background-color: $light-primary;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black-color65;

    &:hover {
      background-color: $action-primary;
    }
  }
  .active {
    background-color: $action-primary;
    color: $white-color;
  }
  .stepper-horizontal-line {
    width: 100%;
    height: 5px;
    background-color: $light-primary;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    .active-section {
      position: absolute;
      background-color: $action-primary;
      left: 0;
      top: 0;
    }
    .width-half {
      width: 50%;
    }
    .width-full {
      width: 100%;
    }
  }
}
