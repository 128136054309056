@import '../../../assets/variable.scss';

.header-main {
  padding: 20px 0;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px;
    .breadcrumb {
      margin: 0;
    }
    .profile-otr {
      display: flex;
      align-items: center;
      gap: 0 12px;
      cursor: pointer;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray-color;
      }
      .user-name {
        color: $black-color65;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
