@import '../../../assets/variable.scss';

.css-1rs26gt:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.async-select,
.table-assigned-provider .search-otr {
  flex: 1;
}
