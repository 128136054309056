/*====================================
    Color Variable Start Here
====================================*/
$action-background: #243F42;
$action-primary: #9B4A2E;
$action-danger: #ef4444;
$background-color: #f2f2f2;
$white-color: #ffffff;
$gray-color: rgba(10, 10, 10, 0.05);
$gray-color4: #0a0a0a;
$black-color20: rgba(15, 15, 15, 0.2);
$black-color40: rgba(15, 15, 15, 0.4);
$black-color65: rgba(15, 15, 15, 0.65);
$light-primary: #ccdee8;
$light-color100: #ffffff;
/*====================================
    Color Variable End Here
====================================*/

/*====================================
        Font Size Start Here
====================================*/

$font-size-h1: 64px;
$font-size-h2: 56px;
$font-size-h3: 48px;
$font-size-h4: 40px;
$font-size-h5: 32px;
$font-size-h6: 24px;
$font-size-lg: 20px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;

/*====================================
        Font Size End Here
====================================*/

/*====================================
        Line height Start Here
====================================*/

$line-height-h1: 77px;
$line-height-h2: 67px;
$line-height-h3: 62px;
$line-height-h4: 52px;
$line-height-h5: 45px;
$line-height-h6: 34px;
$line-height-lg: 34px;
$line-height-md: 32px;
$line-height-sm: 28px;
$line-height-xs: 24px;

/*====================================
        Line height End Here
====================================*/

/*====================================
        Screen Sizes
====================================*/

$small-screen: 576px;

/*====================================
        Max Widths
====================================*/

$max-width-100: 100px;
$max-width-200: 200px;
$max-width-300: 300px;
$max-width-400: 400px;
$max-width-500: 500px;

/*====================================
        Screen End Here
====================================*/
