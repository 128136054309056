@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../../../assets/variable.scss';
// @import '~bootstrap/scss/alert'

.alert {
  border-radius: 8px;
}

@each $state, $value in $theme-colors {
  $alert-background: shift-color($value, -80%);
  $alert-border: $value;
  $alert-color: $value;

  .alert-#{$state} {
    // @include alert-variant($alert-background, $alert-border, $alert-color);
  }
}
