@import '../../assets/variable.scss';

.user-main {
  .table-head-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    .select-main {
      display: flex;
      align-items: center;
      gap: 0 17px;
    }
    .select-text {
      color: $black-color40;
    }
    .search-otr {
      display: flex;
      align-items: center;
      gap: 0 30px;
      .action {
        display: flex;
      }
    }
  }
  .danger-color {
    color: $action-danger;
  }
  .primary-color {
    color: $action-primary;
  }
  .btn-group {
    gap: 0px;
    .btn-success {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      // width: 61px;
      font-size: 12px;
    }
    .btn-danger {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      // width: 61px;
      font-size: 12px;
    }
  }
}
.user-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 50px;
    height: 50px;
  }
}
