@import '../../assets/variable.scss';

.notification-confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 50px;
    height: 50px;
  }
}
