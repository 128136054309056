@import '../../assets/variable.scss';

.event-main {
  background-color: $white-color;
  margin-right: 22px;
  .heading-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-head-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    .select-main {
      display: flex;
      align-items: center;
      gap: 0 17px;
    }
    .select-text {
      color: $black-color40;
    }
    .search-otr {
      display: flex;
      align-items: center;
      gap: 0 30px;
      .action {
        display: flex;
      }
    }
  }
  .danger-color {
    color: $action-danger;
  }
  .primary-color {
    color: $action-primary;
  }

  .image-upload {
    cursor: pointer;
    width: 500px;
    height: 120px;
    background-color: #00a0df13;
    border-radius: 10px;
    display: flex;
    align-items: center;
    svg {
      margin: 0 70px 0 30px;
      color: $action-primary;
    }
    p {
      color: $action-primary;
      margin-top: 16px;
      text-align: center;
      font-size: 18px;
      span {
        font-size: 14px;
      }
    }
  }


  .file-upload {
    cursor: pointer;
    background-color: #00a0df13;
    svg {
      color: $action-primary;
    }
    p {
      color: $action-primary;
      span {
        font-size: 14px;
      }
    }
  }

  .image-upload-gallery {
    width: 100%;
    height: 120px;
    background-color: #00a0df13;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 70px 0 30px;
      color: $action-primary;
    }
    p {
      color: $action-primary;
      margin-top: 16px;
      text-align: center;
      font-size: 18px;
      span {
        font-size: 14px;
      }
    }
  }
}
.speaker-upload-image {
  width: 100%;
  background-color: #00a0df13;
  border-radius: 10px;
  display: flex;
  align-items: center;
  svg {
    margin: 0 70px 0 30px;
    color: $action-primary;
  }
  p {
    color: $action-primary;
    margin-top: 16px;
    text-align: center;
    font-size: 18px;
    span {
      font-size: 14px;
    }
  }
}
.event-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 50px;
    height: 50px;
  }
}
