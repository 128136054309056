@import '../../../assets/variable.scss';

.table-main {
  position: relative;
  .table-head-content {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    .select-main {
      display: flex;
      align-items: center;
      gap: 0 17px;
    }
    .select-text {
      color: $black-color40;
    }
    .search-otr {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0 30px;
      flex: 1;
      justify-content: space-between;
      .search-input-otr {
        flex: 1;
      }
      .search-otr-wrapper {
        width: 230px;
      }
      .action {
        display: flex;
      }
      .search-result {
        position: absolute;
        bottom: -50px;
        .search-value {
          color: $action-primary;
        }
      }
    }
  }
  .table-otr {
    padding-top: 130px;
  }
  .table-responsive {
    overflow-x: auto;
  }

  .table-rows {
    background: #ffffff;
    box-shadow: 0px 0.3em 0.3em rgba(0, 0, 0, 0.1) !important;
  }

  .table-rows th {
    height: 65px;
    vertical-align: middle;
    border: 0px;
    padding-left: 25px !important;
    padding-right: 5px !important;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #0a0a0a;
  }

  .table-rows tr {
    border-top: 1px solid rgba(0, 70, 67, 0.15) !important;
  }

  .table-header tr {
    border-top: none !important;
  }

  .table-rows td {
    height: 81px;
    vertical-align: middle;
    border: 0px;
    padding-left: 25px !important;
    padding-right: 5px !important;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #0a0a0a;
    opacity: 0.65;
  }
  .table-picture {
    object-fit: contain;
    border-radius: 50%;
  }
  .table-text {
    color: $black-color65;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .status {
    padding: 4px 8px;
    gap: 16px;
    text-align: center;
  }

  .status.active {
    background: #dcfce7;
    color: #22c55e;
  }

  .status.inactive {
    background: #fee2e2;
    color: #ef4444;
  }

  .form-check-input {
    border-radius: 0% !important;
  }
  .action-column {
    text-align: center;
  }
  .table-icon-otr {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    .dropdown-toggle::after {
      display: none;
    }
    .icon-otr {
      cursor: pointer;
      width: min-content;
    }
  }
  .table-text-black {
    font-size: 14px;
  }
  .footer-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    .css-bs1igt-ValueContainer {
      padding: 0 12px;
    }
    .css-j18abh-IndicatorsContainer {
      padding: 0;
    }

    .entity-text {
      color: $black-color40;
    }
    .pagination li {
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      &:not(.disabled) {
        cursor: pointer;
      }
    }

    // .icon-otr {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 40px;
    //   height: 40px;
    //   border-radius: 100%;
    //   border: none;
    //   background-color: $black-color40;
    //   transition: 0.3;
    //   margin-bottom: 0;
    //   &:hover {
    //     background-color: $action-primary;
    //     svg {
    //       color: $white-color;
    //     }
    //   }
    //   svg {
    //     color: $black-color40;
    //   }
    // }
    .rc-pagination-item-link,
    .page-linkk-otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      background-color: transparent;
      box-shadow: none;
      .page-linkk {
        color: $black-color40;
      }
    }
    .rc-pagination-item-link:before {
      content: '...';
    }
    .rc-pagination-item-active {
      .page-linkk-otr {
        background-color: $action-primary;
        box-shadow: none;
        .page-linkk {
          color: $white-color;
        }
      }
    }
  }
}
