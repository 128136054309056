@import 'assets/variable.scss';

.sigin-background {
  width: 100%;
  height: 100vh;
  background-color: $action-background;
}
.signin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .app-icon {
    width: 280px;
    margin-bottom: 100px;
  }
}
.signin-form {
  padding: 20px;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 385px;

  h2 {
    text-align: center;
  }

  .input-group {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      color: #555;
    }

    input {
      padding-left: 30px;
      width: 100%;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .forgot-password {
    margin-bottom: 15px;
    text-align: right;

    a {
      color: #555;
      text-decoration: none;
      font-size: 12px;
    }
  }

  .login-button {
    width: 100%;
    height: 40px;
    background-color: #9B4A2E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  /* Media Query for large screens */
  @media (min-width: 769px) {
    width: 500px;
    margin: 0 auto; /* Center the form horizontally on larger screens if needed */
  }
}
